import { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import { CheckoutButtonProps } from '../types';

const CheckoutButton: FC<CheckoutButtonProps> = ({
  className,
  disabled,
  text,
  onClick,
  useButtonComponent = false,
  buttonComponentProps = {},
}) => {
  return (
    <div className={className}>
      {!useButtonComponent && (
        <button
          disabled={disabled}
          className="w-full rounded-md bg-primary-black py-12 font-medium text-white transition hover:bg-gray-500 disabled:cursor-not-allowed disabled:bg-neutral-400"
          onClick={onClick}
          {...buttonComponentProps}
        >
          {text}
        </button>
      )}
      {useButtonComponent && (
        <Button onClick={onClick} disabled={disabled} {...buttonComponentProps}>
          {text}
        </Button>
      )}
    </div>
  );
};

export default CheckoutButton;
