import { useMemo } from 'react';
import { Popover } from '@headlessui/react';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import AccountDropdown from 'components/commercetools-ui/organisms/account/account-atoms/account-dropdown';
import UserIcon from 'components/icons/UserIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';
import { useAccount } from 'frontastic';

const AccountButton = () => {
  const { account, loggedIn } = useAccount();
  const { accountPagePath, loginPagePath } = usePagePathWithLocale();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const userName = useMemo(() => {
    return `${formatAccountMessage({ id: 'hello', defaultMessage: 'Hi, ' })}${
      account?.firstName ?? formatAccountMessage({ id: 'user', defaultMessage: 'User' })
    }`;
  }, [account?.firstName, formatAccountMessage]);

  const title = account
    ? formatAccountMessage({ id: 'account', defaultMessage: 'Account' })
    : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Login' });

  return (
    <div className="h-40" data-test="header-link-profile">
      <Link link={account ? accountPagePath : loginPagePath} title={title}>
        <span className="hidden h-fit w-28 text-secondary-black md:flex lg:hidden">
          <UserIcon />
        </span>
      </Link>

      <Popover as="div" className="relative hidden h-fit lg:block">
        {() => (
          <>
            <Popover.Button title={title}>
              <div className="flex w-fit whitespace-nowrap border-secondary-black hover:border-b-2">
                {loggedIn && (
                  <Typography className="mr-8 mt-2 hidden truncate text-secondary-black lg:block">
                    {userName}
                  </Typography>
                )}

                <div className="pb-8">
                  <span className="w-28 text-secondary-black">
                    <UserIcon />
                  </span>
                </div>
              </div>
            </Popover.Button>

            <Popover.Overlay className="fixed inset-0 z-[310] bg-secondary-black opacity-30" />

            <Popover.Panel className="absolute right-[calc(50%_-_134px)] top-40 z-[310] animate-[appearDropdown_0.15s_ease-in-out] rounded-sm bg-white shadow-400">
              <div className="absolute -top-14 left-[54%] z-10 w-23 -translate-x-1/2 overflow-hidden">
                <div className="h-15 w-15 origin-bottom-left rotate-45 bg-white" />
              </div>
              <AccountDropdown loggedIn={loggedIn} />
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default AccountButton;
