import { FC, useEffect } from 'react';
import { XMarkIcon as CloseIcon } from '@heroicons/react/24/solid';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

export interface Props extends ReactModalProps {
  closeButton?: boolean;
  maxWidth?: number;
}

const Modal: FC<Props> = ({
  children,
  style,
  preventScroll,
  closeButton,
  className = '',
  maxWidth = 800,
  ...props
}) => {
  const [isDesktopSize] = useMediaQuery(desktop);

  const modalStyle: ReactModalProps['style'] = {
    overlay: {
      zIndex: 51,
      backgroundColor: 'rgba(60 ,60, 60, .8)',
      ...style?.overlay,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      maxWidth: isDesktopSize ? maxWidth : 'min(90%, 800px)',
      position: 'relative',
      padding: 0,
      ...style?.content,
    },
  };

  useEffect(() => {
    if (preventScroll) document.body.style.overflow = props.isOpen ? 'hidden' : 'auto';
  }, [props.isOpen, preventScroll]);

  return (
    <ReactModal {...props} ariaHideApp={false} style={modalStyle} preventScroll={preventScroll} className={className}>
      {closeButton && (
        <CloseIcon
          className="absolute right-20 top-20 h-24 w-24 cursor-pointer text-secondary-black"
          onClick={props.onRequestClose}
        />
      )}
      {children}
    </ReactModal>
  );
};

export default Modal;
