import React from 'react';
import CloseIcon from 'components/icons/CloseIcon';
import QuestionThinIcon from 'components/icons/QuestionThinIcon';
import { classnames } from 'helpers/utils/classnames';

export type TooltipProps = {
  message: string | React.ReactNode;
  show: boolean;
  setShow: (show: boolean) => void;
  icon?: React.ReactNode;
  anchor?: 'left' | 'center' | 'right';
  tooltipWrapperMargin?: string;
  additionalWrapperStyles?: string;
};

const Tooltip: React.FC<TooltipProps> = ({
  message,
  show,
  setShow,
  icon = <QuestionThinIcon />,
  anchor = 'left',
  tooltipWrapperMargin = 'mb-8',
  additionalWrapperStyles = '',
}) => {
  const anchorClassname = {
    left: 'left-0',
    center: '-translate-x-1/2',
    right: 'right-0',
  };

  return (
    <div className="flex flex-col md:relative">
      {/* Trigger wrapper */}
      <span className="flex justify-center hover:cursor-pointer" onMouseEnter={() => setShow(true)}>
        {icon}
      </span>

      {/* Tooltip wrapper */}
      <div
        className={classnames(
          `shadow-custom absolute bottom-full flex gap-8 bg-white p-12 pl-20 max-md:w-full md:w-350`,
          !show ? 'hidden' : '',
          anchorClassname[anchor],
          tooltipWrapperMargin,
          additionalWrapperStyles,
        )}
      >
        {/* Content */}
        <div className="flex-1 text-16 leading-[24px] text-neutral-4">{message}</div>

        {/* Close button */}
        <span className="flex justify-center hover:cursor-pointer" onClick={() => setShow(false)}>
          <CloseIcon />
        </span>
      </div>
    </div>
  );
};

export default Tooltip;
