import { LineItem } from 'shared/types/cart/LineItem';
import { Product } from 'shared/types/product/Product';

export type GoogleAnalytics4Item = {
  item_id: string;
  item_name: string;
  item_brand?: string;
  item_category?: string;
  price: number;
  price_net: number;
  google_business_vertical: 'retail';
};

export const productToGoogleAnalytics4Item: (product: Product) => GoogleAnalytics4Item = (product: Product) => {
  const price = product?.variants?.[0]?.price;
  const centAmount = price?.centAmount ?? 0;
  const priceFraction = price?.fractionDigits ?? 2;

  const netPrice = product?.variants?.[0]?.netPrice;
  const netPriceCentAmount = netPrice?.centAmount ?? 0;
  const netPriceFraction = netPrice?.fractionDigits ?? 2;

  return {
    item_id: product?.variants?.[0]?.sku,
    item_name: product?.name,
    item_brand: product?.variants?.[0]?.attributes?.brand?.value,
    item_category: product?.categories?.[0]?.name,
    price: centAmount / 10 ** priceFraction,
    price_net: netPriceCentAmount / 10 ** netPriceFraction,
    google_business_vertical: 'retail',
  } as GoogleAnalytics4Item;
};

export const lineItemToGoogleAnalytics4Item: (lineItem: LineItem) => GoogleAnalytics4Item = (lineItem: LineItem) => {
  const price = lineItem?.price;
  const centAmount = price?.centAmount ?? 0;
  const priceFraction = price?.fractionDigits ?? 2;

  const netPrice = lineItem?.netPrice;
  const netPriceCentAmount = netPrice?.centAmount ?? 0;
  const netPriceFraction = netPrice?.fractionDigits ?? 2;

  return {
    item_id: lineItem?.variant?.sku,
    item_name: lineItem?.name,
    price: centAmount / 10 ** priceFraction,
    price_net: netPriceCentAmount / 10 ** netPriceFraction,
    google_business_vertical: 'retail',
  } as GoogleAnalytics4Item;
};
