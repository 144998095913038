import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Checkbox, { CheckboxProps } from 'components/commercetools-ui/atoms/checkbox';
import Input from 'components/commercetools-ui/atoms/input';
import InputPassword from 'components/commercetools-ui/atoms/input-password';
import Typography from 'components/commercetools-ui/atoms/typography';
import useTrack from 'components/commercetools-ui/organisms/authentication/useTrack';
import { useFormat } from 'helpers/hooks/useFormat';
import useYupValidation from 'helpers/hooks/useYupValidation';
import { useAccount } from 'frontastic';
import { LoginProps } from '.';
import Feedback from '../../account/account-atoms/feedback';

const LoginForm: FC<LoginProps> = ({ onLogin, studio }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //account actions
  const { login, requestPasswordReset } = useAccount();
  const { trackUserLogged } = useTrack();

  const [isValid, setValid] = useState(false);

  //login data
  const [data, setData] = useState({ email: '', password: '', rememberMe: false });

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //attempting to request a password reset
  const [resendPasswordReset, setResendPasswordReset] = useState(false);

  const resetFeedback = () => {
    setError('');
    setSuccess('');
  };

  //get back to login modal
  const backToLogin = () => {
    setResendPasswordReset(false);
    resetFeedback();
  };

  //requesting a password reset
  const toResendPassword = () => {
    setResendPasswordReset(true);
    resetFeedback();
  };

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //handle checkbox input change
  const handleCheckboxChange: CheckboxProps['onChange'] = ({ name, checked }) => {
    setData({ ...data, [name]: checked });
  };

  //login user
  const loginUser = async () => {
    try {
      const response = await login(data.email, data.password, data.rememberMe);

      if (response.accountId) {
        onLogin?.();
        trackUserLogged(response.accountId, response.email);
      } else {
        setError(formatErrorMessage({ id: 'auth.wrong', defaultMessage: 'Wrong email address or password' }));
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //request a password reset for user
  const resendPasswordResetForUser = async () => {
    try {
      await requestPasswordReset(data.email);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  const emailError = formatErrorMessage({ id: 'form.email', defaultMessage: 'Email is invalid' });
  const requiredError = formatErrorMessage({ id: 'form.required', defaultMessage: 'The field is required' });

  const { fieldValidation, formValidation } = useYupValidation();

  const schemaLogin = useMemo(() => {
    return yup.object().shape({
      email: yup.string().required(requiredError).email(emailError),
      password: yup.string().required(requiredError),
    });
  }, [requiredError, emailError]);

  const schemaForgotPassword = useMemo(() => {
    return yup.object().shape({
      email: yup.string().required(requiredError).email(emailError),
    });
  }, [requiredError, emailError]);

  const validateEmail = useCallback(
    (value: string) => {
      return fieldValidation(resendPasswordReset ? schemaForgotPassword : schemaLogin, data, 'email', value);
    },
    [fieldValidation, resendPasswordReset, schemaForgotPassword, schemaLogin, data],
  );

  const validatePassword = useCallback(
    (value: string) => {
      return fieldValidation(schemaLogin, data, 'password', value);
    },
    [fieldValidation, schemaLogin, data],
  );

  useEffect(() => {
    setValid(formValidation(resendPasswordReset ? schemaForgotPassword : schemaLogin, data));
  }, [data, formValidation, resendPasswordReset, schemaForgotPassword, schemaLogin]);

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //processing starts
    setLoading(true);
    //if user is attempting tor equest a password reset
    if (resendPasswordReset) resendPasswordResetForUser();
    //if user wants to login
    else loginUser();
    //processing ends
    setLoading(false);
  };
  return (
    <div className="flex flex-col gap-32">
      <Typography as="h3" className="text-24 font-bold leading-[30px] text-neutral-4">
        {resendPasswordReset ? studio.forgotPasswordHeadline : studio.loginHeadline}
      </Typography>

      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-32">
          <Input
            id="email"
            name="email"
            type="text"
            label={studio.emailFieldLabel}
            required
            value={data['email'] ?? ''}
            onChange={handleChange}
            validationSchema={validateEmail}
          />

          {resendPasswordReset ? null : (
            <InputPassword
              name="password"
              label={studio.passwordFieldLabel}
              required
              value={data['password'] ?? ''}
              onChange={handleChange}
              validationSchema={validatePassword}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          )}

          <div>
            {resendPasswordReset ? null : (
              <div className="mb-32 flex items-center justify-between">
                <label className="flex items-center gap-12">
                  <Checkbox
                    id="remember-me"
                    name="rememberMe"
                    containerClassName="mt-2"
                    checked={data['rememberMe'] ?? ''}
                    onChange={handleCheckboxChange}
                  />
                  <Typography className="text-14 font-normal leading-[17.5px] text-neutral-4">
                    {studio.rememberMeLabel}
                  </Typography>
                </label>

                <Typography
                  className="cursor-pointer text-14 font-normal leading-[17.5px] text-neutral-4 underline"
                  onClick={toResendPassword}
                  data-test="login-form-reset-password"
                >
                  {studio.forgotPasswordText}
                </Typography>
              </div>
            )}
            <div className="flex items-center justify-between">
              <Button
                data-test="login-form-submit"
                type="submit"
                className={resendPasswordReset ? '' : 'w-full'}
                disabled={!isValid || loading}
              >
                {resendPasswordReset ? studio.passwordResetLinkButtonLabel : studio.loginButtonLabel}
              </Button>

              {resendPasswordReset ? (
                <Button variant="secondary" onClick={backToLogin}>
                  {studio.backToLoginButtonLabel}
                </Button>
              ) : null}
            </div>
          </div>
          <Feedback success={success} error={error} />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
