import { useCallback } from 'react';
import { USER_LOGGED, USER_LOGOUT, USER_REGISTRATION } from 'helpers/constants/events';

const useTrack = () => {
  const trackUserLogged = useCallback(async (id: string, email: string) => {
    gtag('event', USER_LOGGED, {
      user_id: id,
      user_email: email,
      user_type: 'registered',
      login_method: 'email',
    });
  }, []);

  const trackUserLogout = useCallback(async (id: string, email: string) => {
    gtag('event', USER_LOGOUT, { user_id: id, user_email: email });
  }, []);

  const trackUserRegistration = useCallback(async (emails: Array<string>) => {
    emails.map((email) => gtag('event', USER_REGISTRATION, { user_email: email, registration_method: 'email' }));
  }, []);

  return { trackUserLogged, trackUserLogout, trackUserRegistration };
};

export default useTrack;
