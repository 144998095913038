function Error() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#FF3F3F" />
      <g clip-path="url(#clip0_7424_91924)">
        <path
          d="M16.464 19.2C16.432 19.2107 16.3947 19.216 16.352 19.216H16.16C15.8613 19.216 15.6053 19.1413 15.392 18.992C15.1893 18.8427 15.088 18.5653 15.088 18.16V11.264H15.184C15.216 11.264 15.248 11.264 15.28 11.264C15.3227 11.2533 15.36 11.248 15.392 11.248C15.6907 11.248 15.9413 11.3227 16.144 11.472C16.3573 11.6213 16.464 11.8987 16.464 12.304V19.2ZM16.464 21.984C16.2933 22.1547 16.0747 22.24 15.808 22.24C15.5307 22.24 15.3067 22.1547 15.136 21.984C14.9653 21.8027 14.88 21.5733 14.88 21.296C14.88 21.0187 14.9653 20.7893 15.136 20.608C15.3067 20.416 15.5307 20.32 15.808 20.32C16.0747 20.32 16.2933 20.416 16.464 20.608C16.6347 20.7893 16.72 21.0187 16.72 21.296C16.72 21.5733 16.6347 21.8027 16.464 21.984Z"
          fill="#FFFFFF"
        />
      </g>
      <rect x="7.5" y="7.5" width="17" height="17" rx="8.5" stroke="#FFFFFF" />
      <defs>
        <clipPath id="clip0_7424_91924">
          <rect x="7" y="7" width="18" height="18" rx="9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Error;
