import { IconProps, SvgBaseDimensions } from './types';

/**
This function enables our custom icon components to use a combination of the props from IconProps and receive the correct value.

If the svg has original default dimensions of 24x24, the component can call the function like
`const finalDimensions = getFinalDimensions(props);` where `props` are the props received by the component

If the svg has original custom dimensions like 20x24, the component can call the function like
`const finalDimensions = getFinalDimensions({ ...props, svgBaseDimensions: { width: W, height: H } });` where `W` and `H` are the base values from the SVG
(note this only affects cases 2 (`scale` prop) and 4 (`no props`))
*/

export function getFinalDimensions({ size, scale, width, height, svgBaseDimensions }: IconProps & SvgBaseDimensions): {
  width: number;
  height: number;
} {
  const DEFAULT_PX = 24;
  const dimensions = {
    width: svgBaseDimensions?.width ?? DEFAULT_PX,
    height: svgBaseDimensions?.height ?? DEFAULT_PX,
  };

  // Case 1. from IconProps <Icon size={24} />
  if (size !== undefined) {
    return { width: size, height: size };
  }

  // Case 2. from IconProps <Icon scale={1.5} />
  if (scale !== undefined) {
    return { width: dimensions.width * scale, height: dimensions.height * scale };
  }

  // Case 3. from IconProps <Icon width={20} height={24} />
  if (width !== undefined && height !== undefined) {
    return { width, height };
  }

  // Case 4. from IconProps <Icon />
  return { width: dimensions.width, height: dimensions.height };
}
