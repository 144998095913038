import React, { FC, ReactNode, useMemo } from 'react';
import { useParams, useRouter } from 'next/navigation';
import useCloseFlyouts from 'helpers/hooks/useCloseFlyouts';
import { useFormat } from 'helpers/hooks/useFormat';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';
import { useCart } from 'frontastic';
import { CartSlideoutProps } from './type';
import CartItem from '../cart/components/cart-item';
import { EmptyState } from '../empty-state';
import InfoMessage from '../info-message';
import OrderOverview from '../order-overview';
import CheckoutButton from '../order-overview/components/checkout-button';
import useCostsData from '../order-payment-section/hooks/useCostsData';

const CartSlideout: FC<CartSlideoutProps> = ({ emptyStateImage, emptyStateTitle }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const title = formatCartMessage({ id: 'order.summary', defaultMessage: 'Order Summary' });

  const { data, isEmpty, hasOutOfStockItems } = useCart();
  const closeFlyouts = useCloseFlyouts();
  const { locale } = useParams();
  const { loading, costsToRender, total } = useCostsData({ dataReference: 'cart' });
  const { cartPagePath } = usePagePathWithLocale();
  const router = useRouter();

  const costData = {
    locale,
    loading,
    costsToRender,
    total,
    freeShipping: data?.freeShipping,
  };

  const discountCodes = data?.discountCodes;

  const lineItems = useMemo(() => {
    return (data?.lineItems ?? []).filter((lineItem) => lineItem.variant?.isOnStock);
  }, [data?.lineItems]);

  const outOfStockLineItems = useMemo(() => {
    return (data?.lineItems ?? []).filter((lineItem) => !lineItem.variant?.isOnStock);
  }, [data?.lineItems]);

  const cartLineItems = [...outOfStockLineItems, ...lineItems];

  const ctaButton: ReactNode = useMemo(() => {
    const ctaButtonClickAction = async () => {
      closeFlyouts();
      router.push(cartPagePath);
    };
    return (
      <CheckoutButton
        useButtonComponent
        buttonComponentProps={{
          variant: 'primary',
          className: 'w-full',
          'data-test': 'drawer-cart-page-order-summary-cta-button',
        }}
        text={formatCartMessage({ id: 'cart.go', defaultMessage: 'Go to cart' })}
        onClick={ctaButtonClickAction}
        disabled={isEmpty || hasOutOfStockItems}
      />
    );
  }, [cartPagePath, closeFlyouts, formatCartMessage, hasOutOfStockItems, isEmpty, router]);

  return (
    <div data-test="cart-panel" className="h-[calc(100vh-56px)] grow">
      {isEmpty ? (
        <EmptyState className="grow" image={emptyStateImage} title={emptyStateTitle} />
      ) : (
        <div className="flex h-full flex-col">
          {hasOutOfStockItems && (
            <div className="flex flex-col px-20 pb-4 pt-24">
              <InfoMessage
                headline={formatCartMessage({
                  id: 'hasOutOfStockItems.headline',
                  defaultMessage: 'Please remove products that are not available to continue checkout',
                })}
                text={formatCartMessage({
                  id: 'hasOutOfStockItems.text',
                  defaultMessage:
                    'Some items in the cart are currently not available. Please remove them from the cart to proceed with the checkout',
                })}
                icon="info"
                iconColor="error"
                additionalClassName="bg-status-danger-bg !p-20"
                headlineClassName="!text-16 !leading-[20px]"
                textClassName="!text-12 !leading-[15px]"
              />
            </div>
          )}
          <div className="shrink grow gap-8 divide-y divide-neutral-400 overflow-auto px-12 pt-8 md:px-22">
            {cartLineItems.map((lineItem) => (
              <CartItem
                key={lineItem.lineItemId}
                item={lineItem}
                variant="mobile"
                page="drawer-cart"
                discountCodes={discountCodes}
              />
            ))}
          </div>
          <OrderOverview costsPropsFromParentPage={costData} ctaButton={ctaButton} page="drawer-cart" title={title} />
        </div>
      )}
    </div>
  );
};

export default CartSlideout;
