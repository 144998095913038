'use client';

import { useParams } from 'next/navigation';
import { GetAccountActionReturn } from '@commercetools/frontend-composable-commerce/lib/types/actions/AccountActions';
import { SDKResponse } from '@commercetools/frontend-sdk';
import { PageResponse } from '@commercetools/frontend-sdk/lib/types/api/page';
// @ts-ignore
import { CloudimageProvider } from '@fyayc-am/next-cloudimage-responsive';
import ToasterProvider from 'components/commercetools-ui/atoms/toaster/provider';
import { FooterProvider } from 'context/footer/footer';
import { sdk } from 'sdk';
import { Translations } from 'types/i18n';
import I18nProvider from './i18n';
import { SWRProvider } from './swr';
import TracingProvider from './tracing';

interface ProvidersProps {
  translations: Translations;
  accountResult?: SDKResponse<GetAccountActionReturn>;
  page: SDKResponse<PageResponse>;
}

export const Providers = ({ translations, accountResult, children, page }: React.PropsWithChildren<ProvidersProps>) => {
  const { locale } = useParams();

  sdk.configureForNext(locale);

  const cloudimageConfig = {
    token: process.env.NEXT_PUBLIC_CLOUDIMAGE_TOKEN,
    apiVersion: 'v7',
    params: {
      force_format: 'webp',
      org_if_sml: 1,
      quality: 70,
    },
  };

  return (
    <TracingProvider page={page}>
      <I18nProvider translations={translations}>
        <SWRProvider value={{ fallback: { '/action/account/getAccount': accountResult } }}>
          <CloudimageProvider config={cloudimageConfig}>
            <FooterProvider>{children}</FooterProvider>
          </CloudimageProvider>
          <ToasterProvider />
        </SWRProvider>
      </I18nProvider>
    </TracingProvider>
  );
};
