import React, { useState, FC, Children, CSSProperties, useRef, useCallback } from 'react';
import SwiperType from 'swiper';
import { Navigation, Pagination, Thumbs, Autoplay, Zoom } from 'swiper/modules'; // eslint-disable-line import/no-unresolved
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import 'swiper/css'; // eslint-disable-line import/no-unresolved
import 'swiper/css/zoom'; // eslint-disable-line import/no-unresolved
import 'swiper/css/navigation'; // eslint-disable-line import/no-unresolved
import 'swiper/css/pagination'; // eslint-disable-line import/no-unresolved
import 'swiper/css/scrollbar'; // eslint-disable-line import/no-unresolved
import { NavigationOptions } from 'swiper/types';
import { ChevronIconProps } from 'components/icons/ChevronRightIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import useTouchDevice from 'helpers/hooks/useTouchDevice';
import { mobile, tablet } from 'helpers/utils/screensizes';
import SliderNavigation, { SliderNavigationProps } from './slider-navigation';

type SliderVariant = 'product-slider' | 'product-advanced-slider' | 'gallery' | 'hero-slider' | 'hero-tile-slider';

export type SliderProps = SliderNavigationProps & {
  allowArrowsOnTouchDevice?: boolean;
  className?: string;
  containerClassName?: string;
  spaceBetween?: string | number;
  slidesPerView?: number;
  dots?: boolean;
  fitToSlides?: boolean;
  slideWidth?: number;
  withThumbs?: boolean;
  variation?: SliderVariant;
  slideWidthIsFlexible?: boolean;
  swiperBackgroundColor?: string;
  ctaLink?: string;
  ctaLabel?: string;
  titleVariant?: string;
  enableZoom?: boolean;
  prevArrowIconProps?: ChevronIconProps;
  nextArrowIconProps?: ChevronIconProps;
} & SwiperProps;

const Slider: FC<SliderProps> = ({
  variation = 'product-slider',
  className = '',
  containerClassName = '',
  slideWidth = 300,
  slidesPerView,
  fitToSlides = false,
  arrows = false,
  innerArrows = false,
  solidArrows = false,
  dots = true,
  spaceBetween = 20,
  withThumbs = false,
  children,
  onSwiper,
  onInit,
  allowTouchMove,
  prevButtonStyles = {},
  nextButtonStyles = {},
  prevArrowStyles = {},
  nextArrowStyles = {},
  prevArrowIconProps = {},
  nextArrowIconProps = {},
  compactNavigation,
  topNavigation,
  bottomNavigation,
  middleNavigation,
  slideWidthIsFlexible,
  allowArrowsOnTouchDevice = false,
  swiperBackgroundColor = 'white',
  // TODO: double-check if ever in use, I couldn't find any reference
  /* eslint-disable @typescript-eslint/no-unused-vars */
  ctaLink,
  ctaLabel,
  titleVariant,
  enableZoom = false,
  /* eslint-enable @typescript-eslint/no-unused-vars */
  ...props
}) => {
  const { isTouchDevice } = useTouchDevice();
  const [isBiggerThanMobileSize] = useMediaQuery(mobile);
  const [isBiggerThanTabletSize] = useMediaQuery(tablet);

  const [init, setInit] = useState(false);

  const handleInit = useCallback(
    (swiper: SwiperType) => {
      setTimeout(() => setInit(true));
      onInit?.(swiper);
    },
    [onInit],
  );

  const navigationPrevRef = useRef<HTMLDivElement>(null);
  const navigationNextRef = useRef<HTMLDivElement>(null);

  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType>();

  const swiperRef = useRef<SwiperType>();

  const validToFit: boolean = !!fitToSlides && !!slideWidth && !!slidesPerView;
  const sliderWidth: CSSProperties['width'] =
    validToFit && slidesPerView ? `${+spaceBetween * (slidesPerView - 1) + slideWidth * slidesPerView}px` : '100%';

  const containerClassNames = useClassNames([
    containerClassName,
    'slider_container relative',
    !init ? 'hidden' : 'block',
    {
      'slider__container--fit': validToFit,
      'slider__container--with-thumbs': withThumbs,
    },
  ]);
  const slidesClassName = useClassNames(['slider', className]);

  const slideStyle = {
    width: slideWidthIsFlexible ? 'fit-content' : `${slideWidth}px`,
  } as React.CSSProperties;

  const slides = Children.map(children, (child) => <SwiperSlide style={slideStyle}>{child}</SwiperSlide>);

  const handleOnSwiper = (swiper: SwiperType) => {
    swiperRef.current = swiper;
    onSwiper?.(swiper);

    if (withThumbs) {
      setThumbsSwiper(swiper);
    }
  };

  const handleOnBeforeInit = (swiper: SwiperType) => {
    (swiper.params.navigation as NavigationOptions).prevEl = navigationPrevRef.current;
    (swiper.params.navigation as NavigationOptions).nextEl = navigationNextRef.current;
  };

  const sliderPaddingBottom = dots
    ? isBiggerThanMobileSize && variation === 'product-slider'
      ? '80px'
      : isBiggerThanTabletSize && variation === 'product-advanced-slider'
      ? '80px'
      : isBiggerThanMobileSize && variation === 'product-advanced-slider'
      ? '25px'
      : !isBiggerThanMobileSize && variation === 'gallery'
      ? '20px'
      : '0'
    : '0';

  const sliderPaginationTop =
    variation === 'product-advanced-slider' ? (isBiggerThanTabletSize ? 'auto' : '-9px') : 'auto';

  const sliderBackgroundColor =
    !isBiggerThanTabletSize && variation === 'product-advanced-slider' ? 'transparent' : swiperBackgroundColor;

  const outBoundsMiddleNavigation = variation === 'product-slider' || variation === 'product-advanced-slider';

  return (
    <>
      {outBoundsMiddleNavigation && (
        <SliderNavigation
          topNavigation={topNavigation}
          bottomNavigation={bottomNavigation}
          middleNavigation={middleNavigation}
          outBoundsMiddleNavigation={outBoundsMiddleNavigation}
          compactNavigation={compactNavigation}
          arrows={allowArrowsOnTouchDevice ? arrows : arrows && !isTouchDevice}
          prevButtonStyles={prevButtonStyles}
          nextButtonStyles={nextButtonStyles}
          prevArrowStyles={prevArrowStyles}
          nextArrowStyles={nextArrowStyles}
          prevArrowIconProps={prevArrowIconProps}
          nextArrowIconProps={nextArrowIconProps}
          navigationPrevRef={navigationPrevRef}
          navigationNextRef={navigationNextRef}
          totalSlides={slides?.length}
          swiperRef={swiperRef?.current}
          innerArrows={innerArrows}
          solidArrows={solidArrows}
        />
      )}
      <div className={containerClassNames}>
        <Swiper
          style={
            {
              '--swiper-pagination-bullet-inactive-color': '#D0ECFF',
              '--swiper-pagination-color': '#33aaff',
              '--swiper-pagination-bullet-inactive-opacity': '1',
              '--swiper-pagination-top': sliderPaginationTop,
              width: sliderWidth,
              paddingBottom: sliderPaddingBottom,
              backgroundColor: sliderBackgroundColor,
            } as CSSProperties
          }
          key={`${allowTouchMove} ${isTouchDevice}`}
          className={slidesClassName}
          modules={[Zoom, Autoplay, Navigation, Pagination, Thumbs]}
          zoom={enableZoom}
          onClick={(swiper) => {
            if (enableZoom) {
              swiper.zoom.toggle();
            }
          }}
          thumbs={{ swiper: thumbsSwiper }}
          pagination={
            dots &&
            (variation === 'hero-slider' ||
              variation === 'hero-tile-slider' ||
              variation === 'gallery' ||
              isBiggerThanMobileSize)
              ? { clickable: true }
              : false
          }
          slidesPerView={slidesPerView ?? 'auto'}
          spaceBetween={spaceBetween}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onSwiper={handleOnSwiper}
          onBeforeInit={handleOnBeforeInit}
          observer
          observeParents
          onInit={handleInit}
          allowTouchMove={allowTouchMove && isTouchDevice}
          {...props}
        >
          {slides}
        </Swiper>
        {!outBoundsMiddleNavigation && (
          <SliderNavigation
            topNavigation={topNavigation}
            bottomNavigation={bottomNavigation}
            middleNavigation={middleNavigation}
            outBoundsMiddleNavigation={outBoundsMiddleNavigation}
            compactNavigation={compactNavigation}
            arrows={allowArrowsOnTouchDevice ? arrows : arrows && !isTouchDevice}
            prevButtonStyles={prevButtonStyles}
            nextButtonStyles={nextButtonStyles}
            prevArrowStyles={prevArrowStyles}
            nextArrowStyles={nextArrowStyles}
            prevArrowIconProps={prevArrowIconProps}
            nextArrowIconProps={nextArrowIconProps}
            navigationPrevRef={navigationPrevRef}
            navigationNextRef={navigationNextRef}
            totalSlides={slides?.length}
            swiperRef={swiperRef?.current}
            innerArrows={innerArrows}
            solidArrows={solidArrows}
          />
        )}
      </div>
    </>
  );
};

export default Slider;
