import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const GeneralSectionSkeleton = () => {
  const columns = Array.from({ length: 4 });
  const links = Array.from({ length: 5 });

  return (
    <div className="grid grid-cols-2 gap-32 md:grid-cols-4">
      {columns.map((_, index) => (
        <div key={index}>
          <p className="mb-4 h-24 w-1/2 text-xl font-bold">
            <Skeleton />
          </p>

          <ul className="flex flex-col gap-4">
            {links.map((_, index) => (
              <li key={index}>
                <Skeleton />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
