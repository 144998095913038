import React from 'react';
import { useRouter } from 'next/navigation';
import { Popover } from '@headlessui/react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import AccountActivationIcon from 'components/icons/account/AccountActivationIcon';
import CreateAccountIcon from 'components/icons/account/CreateAccountIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import usePagePathWithLocale from 'helpers/hooks/usePagePathWithLocale';

const LoggedOut = () => {
  const router = useRouter();
  const { loginPagePath, registerPagePath, activationPagePath } = usePagePathWithLocale();
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  const goToLoginPage = () => router.push(loginPagePath);

  return (
    <div className="w-[288px] p-20">
      <Popover.Button as="div" className="w-full">
        <Button
          variant="primary"
          className="w-full py-12 text-16 leading-[16px]"
          onClick={goToLoginPage}
          data-test="header-link-profile-login"
        >
          {formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
        </Button>
      </Popover.Button>

      <div className="mt-32 flex flex-col gap-20">
        <div className="flex gap-8">
          <div className="w-32">
            <span className="flex h-32 w-32 items-center justify-center rounded-full border border-base-accent-1 bg-base-accent-1 font-bold text-neutral-5">
              <CreateAccountIcon />
            </span>
          </div>
          <div className="flex flex-col gap-4">
            <Typography className="text-16 leading-[24px] text-neutral-4">
              {formatAccountMessage({ id: 'not.member', defaultMessage: 'Not a member' })}
            </Typography>
            <Popover.Button className="w-fit text-start">
              <Link
                data-test="header-link-profile-register"
                link={registerPagePath}
                className="text-16 font-bold leading-[20px] text-base-accent-1"
              >
                {formatAccountMessage({ id: 'join.here', defaultMessage: 'Join here' })}
              </Link>
            </Popover.Button>
          </div>
        </div>
        <div className="flex gap-8">
          <div className="w-32">
            <span className="flex h-32 w-32 items-center justify-center rounded-full border border-base-accent-1 bg-base-accent-1 font-bold text-neutral-5">
              <AccountActivationIcon scale={0.83} />
            </span>
          </div>
          <div className="flex flex-col gap-4">
            <Typography className="text-16 leading-[24px] text-neutral-4">
              {formatAccountMessage({
                id: 'activation.headline',
                defaultMessage: 'Do you already own a Selgros card?',
              })}
            </Typography>
            <Popover.Button className="w-fit text-start">
              <Link
                data-test="header-link-profile-register"
                link={activationPagePath}
                className="text-16 font-bold leading-[20px] text-base-accent-1"
              >
                {formatAccountMessage({
                  id: 'activation.link',
                  defaultMessage: 'In this case, please use our Activation Form',
                })}
              </Link>
            </Popover.Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoggedOut;
