'use client';

import React from 'react';
import NextImage from 'next/image';
// @ts-ignore
import Img from '@fyayc-am/next-cloudimage-responsive';
import Skeleton from 'react-loading-skeleton';
import { useResolvedLocalizedObject } from 'helpers/hooks/useResolvedLocalizedObject';
import useDimensions from './hooks/useDimensions';
import useParameterizedSrc from './hooks/useParameterizedSrc';
import cloudinaryLoader from './loaders/cloudinary';
import defaultLoader from './loaders/default';
import { ImageProps } from './types';

const Image = ({
  media,
  ratio,
  gravity,
  suffix,
  src,
  width,
  height,
  alt = '',
  title,
  cloudimage = false,
  zoomContainer = false,
  ...props
}: ImageProps) => {
  const parameterizedSrc = useParameterizedSrc({ ratio, gravity, suffix, media, src });

  const dimensions = useDimensions({ media, width, height, ...props });

  const resovledTitle = useResolvedLocalizedObject(title ?? '');

  const resolvedAlt = useResolvedLocalizedObject(alt ?? '');

  if (cloudimage) {
    const wisebaseURL = 'https://baza.wisebase.pl';

    return !!src?.toString().startsWith(wisebaseURL) ? (
      <Img
        src={src}
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={`h-full w-full ${zoomContainer ? 'swiper-zoom-container' : ''}`}
        alt={resolvedAlt}
        title={resovledTitle}
        objectFit="contain"
        lazyload={props.loading !== 'eager'}
        {...dimensions}
        {...props}
      />
    ) : (
      <Skeleton className="h-full w-full" />
    );
  }

  if (!media?.mediaId) {
    return (
      <NextImage
        src={parameterizedSrc}
        loader={defaultLoader}
        alt={resolvedAlt}
        title={resovledTitle}
        {...dimensions}
        {...props}
      />
    );
  }

  return (
    <NextImage
      src={parameterizedSrc}
      loader={cloudinaryLoader}
      alt={resolvedAlt}
      title={resovledTitle}
      {...dimensions}
      {...props}
    />
  );
};

export default Image;

export * from './types';
