// Util built as a normal function because hooks have restrictions: https://react.dev/reference/rules/rules-of-hooks

import toast, { ToastOptions } from 'react-hot-toast';
import Toast, { ToastProps } from '../toast';

function triggerToast(props: ToastProps, options?: ToastOptions) {
  toast.custom(() => <Toast {...props} />, options);
}

export default triggerToast;
