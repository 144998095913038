function CreateAccountIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1042 11.4583V8.95828H12.6042V7.70828H15.1042V5.20828H16.3542V7.70828H18.8542V8.95828H16.3542V11.4583H15.1042ZM7.5 9.74349C6.69792 9.74349 6.01132 9.45793 5.44021 8.88682C4.86896 8.31557 4.58333 7.62891 4.58333 6.82682C4.58333 6.02474 4.86896 5.33814 5.44021 4.76703C6.01132 4.19578 6.69792 3.91016 7.5 3.91016C8.30208 3.91016 8.98868 4.19578 9.55979 4.76703C10.131 5.33814 10.4167 6.02474 10.4167 6.82682C10.4167 7.62891 10.131 8.31557 9.55979 8.88682C8.98868 9.45793 8.30208 9.74349 7.5 9.74349ZM1.25 16.0897V14.237C1.25 13.829 1.36083 13.4511 1.5825 13.1033C1.80417 12.7555 2.10042 12.4881 2.47125 12.3012C3.29486 11.8974 4.12576 11.5946 4.96396 11.3927C5.80215 11.1907 6.6475 11.0897 7.5 11.0897C8.3525 11.0897 9.19785 11.1907 10.036 11.3927C10.8742 11.5946 11.7051 11.8974 12.5287 12.3012C12.8996 12.4881 13.1958 12.7555 13.4175 13.1033C13.6392 13.4511 13.75 13.829 13.75 14.237V16.0897H1.25ZM2.5 14.8397H12.5V14.237C12.5 14.0683 12.4511 13.912 12.3533 13.7683C12.2556 13.6247 12.1228 13.5074 11.9552 13.4166C11.2372 13.063 10.5051 12.7951 9.75896 12.6129C9.01271 12.4308 8.25972 12.3397 7.5 12.3397C6.74028 12.3397 5.98729 12.4308 5.24104 12.6129C4.49493 12.7951 3.76285 13.063 3.04479 13.4166C2.87715 13.5074 2.74444 13.6247 2.64667 13.7683C2.54889 13.912 2.5 14.0683 2.5 14.237V14.8397ZM7.5 8.49349C7.95833 8.49349 8.35069 8.3303 8.67708 8.00391C9.00347 7.67752 9.16667 7.28516 9.16667 6.82682C9.16667 6.36849 9.00347 5.97613 8.67708 5.64974C8.35069 5.32335 7.95833 5.16016 7.5 5.16016C7.04167 5.16016 6.64931 5.32335 6.32292 5.64974C5.99653 5.97613 5.83333 6.36849 5.83333 6.82682C5.83333 7.28516 5.99653 7.67752 6.32292 8.00391C6.64931 8.3303 7.04167 8.49349 7.5 8.49349Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default CreateAccountIcon;
