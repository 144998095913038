function CalendarWithDateIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={scale * 24} height={scale * 24} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M5.308 21.5q-.758 0-1.283-.525a1.75 1.75 0 0 1-.525-1.283V6.308q0-.758.525-1.283T5.308 4.5h1.384V2.385h1.539V4.5h7.577V2.385h1.5V4.5h1.384q.758 0 1.283.525t.525 1.283v13.384q0 .758-.525 1.283t-1.283.525zm0-1.5h13.384a.3.3 0 0 0 .212-.096.3.3 0 0 0 .096-.212v-9.384H5v9.384q0 .116.096.212a.3.3 0 0 0 .212.096M5 8.808h14v-2.5a.3.3 0 0 0-.096-.212.3.3 0 0 0-.212-.096H5.308a.3.3 0 0 0-.212.096.3.3 0 0 0-.096.212z"
      ></path>
      <path
        fill="currentColor"
        d="M11.6 18v-5.05l-1.31.66a7 7 0 0 1-.28-.41.8.8 0 0 1-.13-.41q0-.12.07-.24a.67.67 0 0 1 .25-.21l1.42-.74a5 5 0 0 1 .38-.17 1 1 0 0 1 .73-.02q.14.05.22.1V18z"
      ></path>
    </svg>
  );
}

export default CalendarWithDateIcon;
