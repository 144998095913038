function PackageIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={scale * 24} height={scale * 24} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M11.25 19.57v-7.14L5 8.813v6.965q0 .077.038.144a.33.33 0 0 0 .116.116zm1.5 0 6.096-3.533a.33.33 0 0 0 .116-.116.3.3 0 0 0 .038-.144V8.812l-6.25 3.619zm-1.654 1.636-6.692-3.852a1.78 1.78 0 0 1-.904-1.562V8.208a1.77 1.77 0 0 1 .904-1.562l6.692-3.852a1.78 1.78 0 0 1 1.808 0l6.692 3.852a1.78 1.78 0 0 1 .904 1.562v7.584a1.77 1.77 0 0 1-.904 1.562l-6.692 3.852a1.78 1.78 0 0 1-1.808 0M15.856 8.9l2.319-1.33-6.021-3.483A.3.3 0 0 0 12 4.039a.3.3 0 0 0-.154.048l-2.171 1.25zM12 11.14l2.325-1.347L8.15 6.223 5.825 7.57z"
      ></path>
    </svg>
  );
}

export default PackageIcon;
