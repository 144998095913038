import React, { useEffect, useState } from 'react';
import { useParams } from 'next/dist/client/components/navigation';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import { useFormat } from 'helpers/hooks/useFormat';
import { sdk } from 'sdk';
import { PageFolder } from 'frontastic';
import { ContentPagesSectionSkeleton } from './skeleton';

type ContentPage = PageFolder & {
  _url: string;
  breadcrumbs: PageFolder[];
};

type ContentPages = ContentPage[];

type TopLevelContentPage = ContentPage & {
  subpages: ContentPage[];
};

function getSubpages(pages: ContentPages, parentId: string) {
  return pages.filter((page) => {
    if (page.depth === 2 && page.breadcrumbs.length > 0) {
      return page.breadcrumbs[0].pageFolderId === parentId;
    }
  });
}

const ContentPagesSection = () => {
  const [topLevelContentPages, setTopLevelContentPages] = useState<TopLevelContentPage[]>([]);
  const [loading, setLoading] = useState(true);
  const { locale } = useParams();

  sdk.configureForNext(locale);

  useEffect(() => {
    const getContentPages = async () => {
      try {
        const response = await sdk.page.getPages({ path: '/poradniki' });
        if (!response.isError) {
          const contentPages = response.data.pageFolderStructure as ContentPages;
          const topLevelPages = contentPages
            .filter((page) => page.depth === 1)
            .map((page) => ({ ...page, subpages: getSubpages(contentPages, page.pageFolderId) }));
          setTopLevelContentPages(topLevelPages);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getContentPages();
  }, []);

  const { formatMessage } = useFormat({ name: 'common' });
  const sectionTitle = formatMessage({
    id: 'content.pages',
    defaultMessage: 'Content pages',
  });

  return (
    <Container>
      <div className="bg-white p-24">
        <h2 className="pb-20 text-32 font-bold">{sectionTitle}</h2>

        {loading ? (
          <ContentPagesSectionSkeleton />
        ) : (
          <div className="masonry-2 md:masonry-4">
            {topLevelContentPages?.map((topLevelPage) => {
              const link = `/${locale}${topLevelPage._url}`;

              return (
                <div key={topLevelPage.pageFolderId} className="mt-32 first:mt-0">
                  <h3 className="mb-8 text-20 font-bold">
                    <Link link={link}>{topLevelPage.name}</Link>
                  </h3>

                  <ul className="flex flex-col gap-4">
                    {topLevelPage.subpages.map((subpage) => {
                      const subpageLink = `/${locale}${subpage._url}`;

                      return (
                        <li key={subpage.pageFolderId}>
                          <Link link={subpageLink}>{subpage.name}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Container>
  );
};

export default ContentPagesSection;
