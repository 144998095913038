import React, { CSSProperties, FC, LegacyRef } from 'react';
import SwiperType from 'swiper';
import ChevronLeftIcon from 'components/icons/ChevronLeftIcon';
import ChevronRightIcon, { ChevronIconProps } from 'components/icons/ChevronRightIcon';

export type SliderNavigationProps = {
  topNavigation?: boolean;
  bottomNavigation?: boolean;
  middleNavigation?: boolean;
  outBoundsMiddleNavigation?: boolean;
  compactNavigation?: boolean;
  arrows?: boolean;
  innerArrows?: boolean;
  solidArrows?: boolean;
  prevButtonStyles?: CSSProperties;
  nextButtonStyles?: CSSProperties;
  prevArrowStyles?: CSSProperties;
  nextArrowStyles?: CSSProperties;
  prevArrowIconProps?: ChevronIconProps;
  nextArrowIconProps?: ChevronIconProps;
  totalSlides?: number;
  swiperRef?: SwiperType;
  navigationPrevRef?: LegacyRef<HTMLDivElement>;
  navigationNextRef?: LegacyRef<HTMLDivElement>;
};

const SliderNavigation: FC<SliderNavigationProps> = ({
  topNavigation,
  bottomNavigation,
  middleNavigation,
  outBoundsMiddleNavigation,
  compactNavigation,
  arrows,
  innerArrows,
  solidArrows,
  prevButtonStyles,
  nextButtonStyles,
  prevArrowStyles,
  nextArrowStyles,
  prevArrowIconProps,
  nextArrowIconProps,
  totalSlides,
  navigationPrevRef,
  navigationNextRef,
  swiperRef,
}) => {
  if (topNavigation || bottomNavigation || middleNavigation) {
    let navigation = '';
    let navigationArrows = '';

    if (topNavigation) {
      navigation = 'top-[-73px]';
      navigationArrows = 'h-48 w-48 md:flex';
    }
    if (middleNavigation) {
      navigation = `justify-between w-full top-1/2 h-0 ${outBoundsMiddleNavigation ? 'px-0' : 'px-12'}`;
      navigationArrows = `-translate-y-1/2 ${outBoundsMiddleNavigation ? 'h-40 w-40 lg:flex' : 'h-48 w-48 md:flex'}`;
    }

    if (bottomNavigation) {
      navigation = 'bottom-0 px-20 py-24 md:p-32';
      navigationArrows = 'h-48 w-48 md:flex';
    }

    // Arrows used for the Product Slider and its Advanced variant components
    return (
      <div className={`absolute right-0 z-10 flex ${navigation}`}>
        <div ref={navigationPrevRef}>
          <div
            className={`top-arrow shadow-custom hidden shrink-0 cursor-pointer items-center rounded-full bg-white p-10 text-neutral-4 ${navigationArrows}`}
          >
            <ChevronLeftIcon scale={1.2} />
          </div>
        </div>
        <div ref={navigationNextRef} className="top-left-arrow pl-12">
          <div
            className={`top-arrow shadow-custom hidden shrink-0 cursor-pointer items-center rounded-full bg-white p-10 text-neutral-4 ${navigationArrows}`}
          >
            <ChevronRightIcon scale={1.2} />
          </div>
        </div>
      </div>
    );
  }

  const singleImage = totalSlides === 1;
  const innerArrowsClassName = innerArrows ? 'slider_arrow_inner' : '';
  const solidArrowsClassName = solidArrows ? 'slider_arrow_solid' : '';

  return (
    <>
      {/* Normal arrows on the side of the slides. Example: < slide > */}
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="swiper_arrows_container" style={{ display: arrows && !compactNavigation ? 'block' : 'none' }}>
        <div
          ref={navigationPrevRef}
          className={`slider_arrow slider_arrow_prev ${innerArrowsClassName} ${solidArrowsClassName}`}
          style={prevButtonStyles}
        >
          <span className="span-chevron" style={prevArrowStyles}>
            <ChevronLeftIcon {...prevArrowIconProps} />
          </span>
        </div>
        <div
          ref={navigationNextRef}
          className={`slider_arrow slider_arrow_next ${innerArrowsClassName} ${solidArrowsClassName}`}
          style={nextButtonStyles}
        >
          <span className="span-chevron" style={nextArrowStyles}>
            <ChevronRightIcon {...nextArrowIconProps} />
          </span>
        </div>
      </div>

      {/* Bottom arrows with page indicator. Example: < 1/3 > */}
      <div
        className="mt-10 items-center justify-center gap-16"
        style={{ display: compactNavigation ? 'flex' : 'none' }}
      >
        {!singleImage && (
          <span onClick={() => swiperRef?.slidePrev()}>
            <ChevronLeftIcon />
          </span>
        )}

        <div className="flex font-body text-14 font-regular leading-loose text-secondary-black">
          <span>{(swiperRef?.realIndex ?? 0) + 1}</span>
          <span>/</span>
          <span>{totalSlides}</span>
        </div>

        {!singleImage && (
          <span onClick={() => swiperRef?.slideNext()}>
            <ChevronRightIcon />
          </span>
        )}
      </div>
    </>
  );
};

export default SliderNavigation;
