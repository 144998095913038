import React from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import { useFormat } from 'helpers/hooks/useFormat';
import { useProduct } from 'frontastic';
import { CategoriesSectionSkeleton } from './skeleton';

const CategoriesSection = () => {
  const { categories, categoriesLoading: loading } = useProduct();

  const { formatMessage } = useFormat({ name: 'common' });
  const sectionTitle = formatMessage({
    id: 'categories.map',
    defaultMessage: 'Categories map',
  });

  return (
    <Container>
      <div className="bg-white p-24">
        <h2 className="pb-20 text-32 font-bold">{sectionTitle}</h2>
        <div>
          {loading ? (
            <CategoriesSectionSkeleton />
          ) : (
            <div className="masonry-2 md:masonry-4">
              {categories?.map((c) => {
                if (c.depth === 1) {
                  return (
                    <div key={c.categoryId} className="mt-32 first:mt-0">
                      <h3 className="mb-8 text-20 font-bold">
                        <Link link={c.path}>{c.name}</Link>
                      </h3>

                      <ul className="flex flex-col gap-4">
                        {c.subCategories.map((sc) => {
                          if (sc.productsAmount > 0) {
                            return (
                              <li key={sc.categoryId}>
                                <Link link={sc.path}>{sc.name}</Link>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CategoriesSection;
