function AccountActivationIcon({ scale = 1 }) {
  return (
    <svg width={24 * scale} height={24 * scale} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.30775 22C3.80258 22 3.375 21.825 3.025 21.475C2.675 21.125 2.5 20.6974 2.5 20.1923V9.80775C2.5 9.30258 2.675 8.875 3.025 8.525C3.375 8.175 3.80258 8 4.30775 8H9.5V4.5C9.5 4.08467 9.64617 3.73083 9.9385 3.4385C10.2308 3.14617 10.5847 3 11 3H13C13.4153 3 13.7692 3.14617 14.0615 3.4385C14.3538 3.73083 14.5 4.08467 14.5 4.5V8H19.6923C20.1974 8 20.625 8.175 20.975 8.525C21.325 8.875 21.5 9.30258 21.5 9.80775V20.1923C21.5 20.6974 21.325 21.125 20.975 21.475C20.625 21.825 20.1974 22 19.6923 22H4.30775ZM4.30775 20.5H19.6923C19.7821 20.5 19.8558 20.4712 19.9135 20.4135C19.9712 20.3558 20 20.2821 20 20.1923V9.80775C20 9.71792 19.9712 9.64417 19.9135 9.5865C19.8558 9.52883 19.7821 9.5 19.6923 9.5H14.5V9.88475C14.5 10.2936 14.3523 10.6458 14.0568 10.9413C13.7613 11.2368 13.409 11.3845 13 11.3845H11C10.591 11.3845 10.2388 11.2368 9.94325 10.9413C9.64775 10.6458 9.5 10.2936 9.5 9.88475V9.5H4.30775C4.21792 9.5 4.14417 9.52883 4.0865 9.5865C4.02883 9.64417 4 9.71792 4 9.80775V20.1923C4 20.2821 4.02883 20.3558 4.0865 20.4135C4.14417 20.4712 4.21792 20.5 4.30775 20.5ZM6.15375 18.2115H11.8463V17.9152C11.8463 17.6576 11.7751 17.4208 11.6328 17.2048C11.4904 16.9888 11.2936 16.8205 11.0423 16.7C10.7154 16.5563 10.3843 16.4487 10.049 16.377C9.71383 16.3052 9.36417 16.2693 9 16.2693C8.63583 16.2693 8.28617 16.3052 7.951 16.377C7.61567 16.4487 7.28458 16.5563 6.95775 16.7C6.70642 16.8205 6.50958 16.9888 6.36725 17.2048C6.22492 17.4208 6.15375 17.6576 6.15375 17.9152V18.2115ZM14 16.6923H18V15.5H14V16.6923ZM9 15.5C9.37183 15.5 9.68917 15.3686 9.952 15.1058C10.2148 14.8429 10.3463 14.5256 10.3463 14.1538C10.3463 13.7821 10.2148 13.4648 9.952 13.202C9.68917 12.9392 9.37183 12.8077 9 12.8077C8.62817 12.8077 8.31083 12.9392 8.048 13.202C7.78517 13.4648 7.65375 13.7821 7.65375 14.1538C7.65375 14.5256 7.78517 14.8429 8.048 15.1058C8.31083 15.3686 8.62817 15.5 9 15.5ZM14 14H18V12.8077H14V14ZM11 9.88475H13V4.5H11V9.88475Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default AccountActivationIcon;
