import { IconProps } from './types';
import { getFinalDimensions } from './utils';

function CopyIcon(props: IconProps) {
  const finalDimensions = getFinalDimensions(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={finalDimensions.width}
      height={finalDimensions.height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.584 5.653h-7.18a.78.78 0 0 0-.774.78v7.231c0 .431.347.78.775.78h7.18c.427 0 .774-.349.774-.78v-7.23a.78.78 0 0 0-.775-.781M10.404 4h7.18A2.425 2.425 0 0 1 20 6.433v7.231a2.425 2.425 0 0 1-2.416 2.434h-7.18a2.425 2.425 0 0 1-2.415-2.434v-7.23A2.425 2.425 0 0 1 10.405 4M4.822 7.75c.453 0 .82.37.82.826v8.99c0 .208.082.406.227.553a.77.77 0 0 0 .548.228h9.8c.454 0 .821.37.821.827a.824.824 0 0 1-.82.826h-9.8c-.642 0-1.256-.256-1.71-.713A2.44 2.44 0 0 1 4 17.567V8.576c0-.457.367-.827.82-.827"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CopyIcon;
