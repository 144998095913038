import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import useClassNames from 'helpers/hooks/useClassNames';
import { Reference } from 'types/reference';

type Props = {
  text: string;
  style: string;
  link: Reference;
};

export default function PromoButton({ text, style, link }: Props) {
  return (
    <Link
      link={link}
      className={useClassNames([
        'rounded-full px-16 py-4 text-14 font-bold leading-[18px]',
        { 'bg-neutral-5 text-neutral-6': style === 'Light' },
        { 'bg-neutral-6 text-neutral-5': style === 'Dark' },
      ])}
    >
      <Typography>{text}</Typography>
    </Link>
  );
}
