import { ChevronIconProps } from './ChevronRightIcon';

function ChevronLeftIcon({ scale = 1 }: ChevronIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path fill="currentColor" d="M10.454 12l4.6 4.6L14 17.654 8.346 12 14 6.346 15.054 7.4l-4.6 4.6z"></path>
    </svg>
  );
}

export default ChevronLeftIcon;
