import { useState } from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import CheckmarkIcon from 'components/icons/CheckmarkIcon';
import CopyIcon from 'components/icons/CopyIcon';
import CouponIcon from 'components/icons/CouponIcon';
import useClassNames from 'helpers/hooks/useClassNames';

type Props = {
  text: string;
  style: string;
};

export default function PromoBadge({ text, style }: Props) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    if (!copied) {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  return (
    <div className="flex text-14 font-bold leading-[18px] text-neutral-5">
      <div
        className={useClassNames([
          'flex items-center gap-4 rounded-l-full px-8 py-4',
          { 'bg-base-brand-1': style === 'Red' },
          { 'bg-[#62B135]': style === 'Green' },
          { 'bg-base-accent-1': style === 'Blue' },
        ])}
      >
        <CouponIcon size={18} />
        <Typography>{text}</Typography>
      </div>
      <div
        className={useClassNames([
          'flex items-center justify-center rounded-r-full py-4 pl-4 pr-8 transition-colors',
          { 'bg-base-brand-2 hover:bg-[#99001A]': style === 'Red' },
          { 'bg-[#99001A]': style === 'Red' && copied },
          { 'bg-[#518E2F] hover:bg-[#3F6F25]': style === 'Green' },
          { 'bg-[#3F6F25]': style === 'Green' && copied },
          { 'bg-[#005DA0] hover:bg-[#004170]': style === 'Blue' },
          { 'bg-[#004170]': style === 'Blue' && copied },
          { 'cursor-pointer': !copied },
        ])}
        onClick={copyToClipboard}
      >
        {copied ? <CheckmarkIcon size={18} /> : <CopyIcon size={18} />}
      </div>
    </div>
  );
}
