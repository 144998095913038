function Success() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#90C473" />
      <rect x="7.6" y="7.6" width="16.8" height="16.8" rx="8.4" stroke="#FFFFFF" stroke-width="1.2" />
      <path
        d="M14.5708 19.2981L11.459 16.1863L12.0827 15.5626L14.5708 18.0507L19.9173 12.7042L20.541 13.3279L14.5708 19.2981Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}

export default Success;
