import React, { createContext, useState, useContext, ReactNode } from 'react';
import { FooterLink } from 'components/commercetools-ui/organisms/footer/atoms/column';

type FooterColumn = {
  header: string;
  links: FooterLink[];
};

type FooterContextType = {
  footerColumns: FooterColumn[];
  setFooterColumns: (columns: FooterColumn[]) => void;
};

const FooterContext = createContext<FooterContextType | undefined>(undefined);

export const FooterProvider = ({ children }: { children: ReactNode }) => {
  const [footerColumns, setFooterColumns] = useState<FooterColumn[]>([]);

  return <FooterContext.Provider value={{ footerColumns, setFooterColumns }}>{children}</FooterContext.Provider>;
};

export const useFooterColumns = () => {
  const context = useContext(FooterContext);
  if (context === undefined) {
    throw new Error('useFooterColumns must be used within a FooterProvider');
  }
  return context;
};
