function Info() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="32" height="32" rx="16" fill="#64B1BC" />
      <path
        d="M15.25 20.75H16.75V15H15.25V20.75ZM16 13.2885C16.2288 13.2885 16.4207 13.2111 16.5755 13.0563C16.7303 12.9015 16.8077 12.7096 16.8077 12.4808C16.8077 12.2519 16.7303 12.0601 16.5755 11.9053C16.4207 11.7505 16.2288 11.6731 16 11.6731C15.7711 11.6731 15.5793 11.7505 15.4245 11.9053C15.2697 12.0601 15.1923 12.2519 15.1923 12.4808C15.1923 12.7096 15.2697 12.9015 15.4245 13.0563C15.5793 13.2111 15.7711 13.2885 16 13.2885ZM16.0016 25.5C14.6877 25.5 13.4527 25.2506 12.2966 24.752C11.1404 24.2533 10.1347 23.5765 9.2795 22.7217C8.42427 21.8669 7.74721 20.8616 7.24833 19.706C6.74944 18.5504 6.5 17.3156 6.5 16.0017C6.5 14.6877 6.74933 13.4527 7.248 12.2966C7.74667 11.1404 8.42342 10.1347 9.27825 9.2795C10.1331 8.42427 11.1383 7.74721 12.294 7.24833C13.4496 6.74944 14.6844 6.5 15.9983 6.5C17.3122 6.5 18.5473 6.74933 19.7034 7.248C20.8596 7.74667 21.8652 8.42342 22.7205 9.27825C23.5757 10.1331 24.2527 11.1383 24.7516 12.294C25.2505 13.4496 25.5 14.6844 25.5 15.9983C25.5 17.3122 25.2506 18.5473 24.752 19.7034C24.2533 20.8596 23.5765 21.8652 22.7217 22.7205C21.8669 23.5757 20.8616 24.2527 19.706 24.7516C18.5504 25.2505 17.3156 25.5 16.0016 25.5ZM16 24C18.2333 24 20.125 23.225 21.675 21.675C23.225 20.125 24 18.2333 24 16C24 13.7666 23.225 11.875 21.675 10.325C20.125 8.77498 18.2333 7.99998 16 7.99998C13.7666 7.99998 11.875 8.77498 10.325 10.325C8.77498 11.875 7.99998 13.7666 7.99998 16C7.99998 18.2333 8.77498 20.125 10.325 21.675C11.875 23.225 13.7666 24 16 24Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}

export default Info;
