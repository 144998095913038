import { Currency } from '@commercetools/frontend-sdk/lib/types/Currency';

interface LocalizationMapping {
  locale: string;
  currency: Currency;
  currencyCode: string;
  countryName: string;
  countryCode: string;
  countryKey: string;
}

const localizationMapper = {
  pl: {
    locale: 'pl_PL',
    currency: 'PLN',
    currencyCode: 'zł',
    countryCode: 'PL',
    countryName: 'Poland',
    countryKey: 'pl',
  },
} as Record<string, LocalizationMapping>;

export const getLocalizationInfo = (locale: string) => {
  if (!(locale in localizationMapper)) {
    console.log('hello, world');
    console.warn(
      `Invalid locale ${locale} provided. Possible values are ${Object.keys(localizationMapper).join(', ')}`,
    );

    return localizationMapper.pl;
  }

  return localizationMapper[locale as keyof typeof localizationMapper];
};
