export type ChevronIconProps = { scale?: number };

function ChevronRightIcon({ scale = 1 }: ChevronIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path fill="currentColor" d="M12.946 12l-4.6-4.6L9.4 6.346 15.054 12 9.4 17.654 8.346 16.6l4.6-4.6z"></path>
    </svg>
  );
}

export default ChevronRightIcon;
