function MobilePhoneIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={scale * 24} height={scale * 24} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M7.308 22.5q-.758 0-1.283-.525a1.75 1.75 0 0 1-.525-1.283V3.308q0-.758.525-1.283T7.308 1.5h9.384q.758 0 1.283.525t.525 1.283v17.384q0 .758-.525 1.283t-1.283.525zM7 17.77v2.922q0 .116.096.212a.3.3 0 0 0 .212.096h9.384a.3.3 0 0 0 .212-.096.3.3 0 0 0 .096-.212V17.77zm5 2.5a.85.85 0 0 0 .626-.26.85.85 0 0 0 .258-.626.85.85 0 0 0-.258-.625A.85.85 0 0 0 12 18.5a.85.85 0 0 0-.626.259.85.85 0 0 0-.258.625q0 .368.258.627a.85.85 0 0 0 .626.258m-5-4h10V5.75H7zM7 4.25h10v-.942a.3.3 0 0 0-.096-.212.3.3 0 0 0-.212-.096H7.308a.3.3 0 0 0-.212.096.3.3 0 0 0-.096.212z"
      ></path>
    </svg>
  );
}

export default MobilePhoneIcon;
