import React, { useEffect, useState } from 'react';
import { Discount } from 'shared/types/cart';
import AccordionBtn, { AccordionProps } from 'components/commercetools-ui/atoms/accordion';
import Button from 'components/commercetools-ui/atoms/button';
import { DiscountLabel } from 'components/commercetools-ui/atoms/discountLabel';
import triggerToast from 'components/commercetools-ui/atoms/toaster/utils/triggerToast';
import ArrowRightIcon from 'components/icons/ArrowRightIcon';
import InfoIcon from 'components/icons/InfoIcon';
import TrashIcon from 'components/icons/TrashIcon';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';

export interface Props {
  accordionProps?: AccordionProps;
  additionalWrapperClassName?: string;
}

const DiscountForm: React.FC<Props> = ({ accordionProps, additionalWrapperClassName }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  const [code, setCode] = useState('');
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [codeIsInvalid, setCodeIsInvalid] = useState(false);
  const { removeDiscountCode, replaceDiscount, data } = useCart();

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setDiscounts(data?.discountCodes ?? []);
  }, [data]);

  const onApplyDiscount = () => {
    if (processing || !code) return;

    setCodeIsInvalid(false);
    setProcessing(true);

    replaceDiscount?.(code)
      .then(() => {
        setCode('');
        if (discounts && !!discounts.length) {
          triggerToast({
            message: formatCartMessage({
              id: 'secondDiscount',
              defaultMessage: 'Only one discount code can be active',
            }),
          });
        }
      })
      .catch(() => {
        setCodeIsInvalid(true);
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    setCodeIsInvalid(false);
  };

  const handleRemove = (discount: Discount) => {
    removeDiscountCode?.(discount);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onApplyDiscount();
  };

  // Styles
  const wrapperClassName = 'bg-base-accent-3 pt-32 px-20' + ' ' + additionalWrapperClassName;
  const inputClassName = useClassNames([
    'h-48 rounded-l-full px-20 w-full border border-neutral-2 placeholder:text-neutral-3 disabled:bg-neutral-300 focus:outline-none focus:border-neutral-2',
    { '!border-status-danger-border text-status-danger-border focus:border-status-danger-border': codeIsInvalid },
  ]);
  const discountsContainerClassName = useClassNames(['mt-8 flex flex-col', discounts?.length === 0 ? 'pt-0' : 'pt-4']);

  // Render
  return (
    <div className={wrapperClassName}>
      <AccordionBtn
        closedSectionTitle={formatCartMessage({ id: 'discount.apply', defaultMessage: 'Apply a discount' })}
        dataTest="discount-code-accordion"
        startOpen={discounts?.length !== 0}
        {...accordionProps}
      >
        <>
          <div className="mt-12">
            <div className="flex w-full">
              <input
                className={inputClassName}
                value={code}
                placeholder={formatCartMessage({
                  id: 'cart.discount.enter',
                  defaultMessage: 'Enter discount code',
                })}
                onChange={handleChange}
                disabled={processing}
                data-test="discount-code-input"
              />
              <Button
                variant="primary"
                className="rounded-l-none rounded-r-[48px] p-10"
                onClick={handleSubmit}
                loading={processing}
                size="fit"
                data-test="send-discount-button"
              >
                <ArrowRightIcon />
              </Button>
            </div>

            {/* Error message */}
            {codeIsInvalid && (
              <div className="mt-12 flex items-center gap-4 text-status-danger-border lg:max-w-[350px]">
                <InfoIcon />
                <p className="text-14 leading-[17.5px]">
                  {formatCartMessage({ id: 'codeNotValid', defaultMessage: 'The discount code is not valid' })}
                </p>
              </div>
            )}
          </div>

          {/* Discounts list */}
          {discounts && !!discounts.length && (
            <div className={discountsContainerClassName}>
              {discounts
                .filter((discount) => discount.state === 'MatchesCart')
                .map((discount, index) => (
                  <div
                    key={discount.discountId}
                    className={`flex flex-col gap-4 border-x border-b border-base-accent-2 bg-neutral-5 p-12 ${
                      index === 0 ? 'rounded-t-md border-t' : ''
                    } ${index === discounts.length - 1 ? 'rounded-b-md' : ''}`}
                  >
                    <div className="flex items-center justify-between">
                      <DiscountLabel discount={discount} />
                      <button
                        className="h-24 w-24 rounded-full bg-base-accent-3 pl-4"
                        type="button"
                        onClick={() => handleRemove(discount)}
                      >
                        <TrashIcon scale={0.67} />
                      </button>
                    </div>
                    <p className="text-14 leading-[17.5px] text-neutral-4">{discount.description}</p>
                  </div>
                ))}
            </div>
          )}
        </>
      </AccordionBtn>
    </div>
  );
};

export default DiscountForm;
