import React from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import { useFooterColumns } from 'context/footer/footer';
import { useFormat } from 'helpers/hooks/useFormat';
import { GeneralSectionSkeleton } from './skeleton';

const GeneralSection = () => {
  const { footerColumns } = useFooterColumns();
  const loading = footerColumns.length === 0;

  const { formatMessage } = useFormat({ name: 'common' });
  const sectionTitle = formatMessage({
    id: 'sitemap',
    defaultMessage: 'Sitemap',
  });

  return (
    <Container>
      <div className="bg-white p-24">
        <h2 className="pb-20 text-32 font-bold">{sectionTitle}</h2>

        {loading ? (
          <GeneralSectionSkeleton />
        ) : (
          <div className="grid grid-cols-2 gap-32 md:grid-cols-4">
            {footerColumns.map((column) => (
              <div key={column.header}>
                <h3 className="mb-8 text-20 font-bold">{column.header}</h3>

                <ul className="flex flex-col gap-4">
                  {column.links.map((link) => (
                    <li key={link.name}>
                      <Link key={link.name} link={link.reference}>
                        {link.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};

export default GeneralSection;
