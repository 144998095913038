import Markdown from 'react-markdown';
import Link from 'components/commercetools-ui/atoms/link';
import PromoBadge from './promo-badge';
import PromoButton from './promo-button';
import { PromoBarProps } from './types';
import { getIconElement } from './utils';

export interface Props {
  data: PromoBarProps;
}

const PromoBar: React.FC<Props> = ({ data }) => {
  let IconComponent;
  if (data.iconGroup && data.iconGroup.length > 0) {
    const { icon, iconColor } = data.iconGroup[0];
    const iconElement = getIconElement(icon);

    IconComponent = function IconComponent() {
      return <span style={{ color: iconColor }}>{iconElement}</span>;
    };
  }

  let MarkdownComponent;
  if (data.textGroup && data.textGroup.length > 0) {
    const { text, textSize, textColor } = data.textGroup[0];

    MarkdownComponent = function MarkdownComponent() {
      return (
        <div style={{ fontSize: Number(textSize), color: textColor }} className="leading-normal">
          <Markdown
            components={{
              a(props) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { node, href, ref, ...rest } = props;
                return <Link link={href} target="_blank" className="underline" {...rest} />;
              },
              strong(props) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { node, ...rest } = props;
                return <strong className="font-bold" {...rest} />;
              },
            }}
          >
            {text}
          </Markdown>
        </div>
      );
    };
  }

  let RightSideComponent;
  if (data.rightSideGroup && data.rightSideGroup.length > 0) {
    const rightSideGroup = data.rightSideGroup[0];
    const { rightSide } = rightSideGroup;
    if (rightSide === 'Empty') {
      RightSideComponent = undefined;
    }
    if (rightSide === 'Promo code') {
      if (rightSideGroup.promoCodeGroup && rightSideGroup.promoCodeGroup.length > 0) {
        const { promoCodeText, promoCodeStyle } = rightSideGroup.promoCodeGroup[0];

        RightSideComponent = function RightSideComponent() {
          return <PromoBadge text={promoCodeText} style={promoCodeStyle} />;
        };
      }
    }
    if (rightSide === 'Button') {
      if (rightSideGroup.buttonGroup && rightSideGroup.buttonGroup.length > 0) {
        const { buttonText, buttonStyle, buttonLink } = rightSideGroup.buttonGroup[0];

        RightSideComponent = function RightSideComponent() {
          return <PromoButton text={buttonText} style={buttonStyle} link={buttonLink} />;
        };
      }
    }
  }

  return (
    <div className="p-12 md:p-8" style={{ backgroundColor: data.backgroundColor }}>
      <div className="flex h-full items-center justify-center gap-12">
        <div className="flex items-center gap-4">
          {IconComponent ? <IconComponent /> : null}
          {MarkdownComponent ? <MarkdownComponent /> : null}
        </div>

        {RightSideComponent ? (
          <div className="flex shrink-0">
            <RightSideComponent />
          </div>
        ) : null}
      </div>
    </div>
  );
};
export default PromoBar;
