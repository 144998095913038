'use client';

import React, { createContext } from 'react';
import { Category } from 'shared/types/product';
import Header from 'components/commercetools-ui/organisms/header';
import { HeaderProps, Market } from 'components/commercetools-ui/organisms/header/types';
import PromoBar from 'components/commercetools-ui/organisms/promo-bar';
import { PromoBarProps } from 'components/commercetools-ui/organisms/promo-bar/types';
import { MarketProvider } from 'context/market';
import { useAccount, useProduct } from 'frontastic';
import { TasticProps } from '../types';

const initialMarketState = {
  market: {} as Market,
  markets: [] as Market[],
  handleMarket: {} as (market: Market) => void,
};
export const MarketContext = createContext(initialMarketState);

const HeaderTastic = ({ data }: TasticProps<HeaderProps & PromoBarProps>) => {
  const { categories = [] } = useProduct();

  const isPromoBar = data.backgroundColor && data.iconGroup && data.textGroup && data.rightSideGroup;

  const promoBarData = {
    backgroundColor: data.backgroundColor,
    iconGroup: data.iconGroup,
    textGroup: data.textGroup,
    rightSideGroup: data.rightSideGroup,
  };

  const { account, logout } = useAccount();

  const getCategoriesWithProducts = (categories: Category[]): Category[] => {
    const categoriesWithProducts = categories
      .filter((category) => category.productsAmount > 0)
      .map((category) => {
        return { ...category, subCategories: getCategoriesWithProducts(category.subCategories) };
      });

    return categoriesWithProducts;
  };

  const getNavLinks = () => {
    const rootCategories = categories.filter((category) => category.depth === 0);
    const navLinks = getCategoriesWithProducts(rootCategories);

    return navLinks;
  };

  return (
    <MarketProvider>
      <div id="header-container" className="fixed top-0 z-50 w-full">
        {isPromoBar ? <PromoBar data={promoBarData} /> : null}

        <Header
          navLinks={getNavLinks()}
          categories={categories}
          emptyCartTitle={data.emptyCartTitle}
          emptyCartSubtitle={data.emptyCartSubtitle}
          emptyCartImage={data.emptyCartImage}
          emptyCartCategories={data.emptyCartCategories}
          emptyWishlistTitle={data.emptyWishlistTitle}
          emptyWishlistSubtitle={data.emptyWishlistSubtitle}
          emptyWishlistImage={data.emptyWishlistImage}
          emptyWishlistCategories={data.emptyWishlistCategories}
          contentLinks={data.contentLinks}
          account={account}
          tiles={data.tiles}
          logout={logout}
        />
      </div>
    </MarketProvider>
  );
};
export default HeaderTastic;
