'use client';

import React from 'react';
import Sitemap from 'components/commercetools-ui/organisms/sitemap';

const SitemapTastic = () => {
  return <Sitemap />;
};

export default SitemapTastic;
