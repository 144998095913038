function ClockwiseIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12.01 20.5a8.255 8.255 0 01-3.316-.67 8.605 8.605 0 01-2.696-1.818 8.607 8.607 0 01-1.819-2.697A8.256 8.256 0 013.509 12c0-1.18.224-2.285.67-3.315a8.606 8.606 0 011.82-2.697A8.607 8.607 0 018.693 4.17 8.256 8.256 0 0112.01 3.5c1.257 0 2.452.264 3.584.793a8.488 8.488 0 012.916 2.23v-2.37h1.5v5.078h-5.077v-1.5h2.615a7.558 7.558 0 00-2.467-2.003A6.686 6.686 0 0012.01 5c-1.95 0-3.604.68-4.963 2.037C5.69 8.396 5.01 10.05 5.01 12c0 1.95.679 3.604 2.037 4.962C8.406 18.322 10.06 19 12.01 19c1.75 0 3.276-.568 4.578-1.705 1.302-1.136 2.079-2.568 2.331-4.295h1.531c-.244 2.149-1.17 3.936-2.779 5.361-1.609 1.426-3.496 2.139-5.661 2.139zm2.973-4.473l-3.723-3.723V7h1.5v4.696l3.277 3.277-1.054 1.054z"
      ></path>
    </svg>
  );
}

export default ClockwiseIcon;
