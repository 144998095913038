import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';
import Error from 'components/icons/toast/Error';
import Info from 'components/icons/toast/Info';
import Success from 'components/icons/toast/Success';

export interface ToastProps {
  message: string;
  variant?: 'info' | 'success' | 'error';
}

const Toast: React.FC<ToastProps> = ({ message, variant = 'info' }) => {
  const icons = {
    error: Error,
    info: Info,
    success: Success,
  };

  const Icon = icons[variant];

  return (
    <div className="shadow-custom flex max-w-sm items-start gap-12 rounded-xl bg-neutral-5 px-20 py-12">
      <span>
        <Icon />
      </span>
      <Typography className="mt-4 text-16 leading-[24px] text-neutral-4">{message}</Typography>
    </div>
  );
};

export default Toast;
