import React from 'react';
import CategorySection from './categories';
import ContentPagesSection from './content-pages';
import GeneralSection from './general';

const Sitemap = () => {
  return (
    <>
      <GeneralSection />
      <CategorySection />
      <ContentPagesSection />
    </>
  );
};

export default Sitemap;
