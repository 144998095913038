import CalendarIcon from 'components/icons/CalendarIcon';
import CalendarWithDateIcon from 'components/icons/CalendarWithDateIcon';
import CartIcon from 'components/icons/CartIcon';
import ClockwiseIcon from 'components/icons/ClockwiseIcon';
import CouponIcon from 'components/icons/CouponIcon';
import ExclamationMarkIcon from 'components/icons/ExclamationMarkIcon';
import HeartIcon from 'components/icons/HeartIcon';
import HomeIcon from 'components/icons/HomeIcon';
import InfoIcon from 'components/icons/InfoIcon';
import LockIcon from 'components/icons/LockIcon';
import MobilePhoneIcon from 'components/icons/MobilePhoneIcon';
import MoneyIcon from 'components/icons/MoneyIcon';
import PackageIcon from 'components/icons/PackageIcon';
import QuestionThinIcon from 'components/icons/QuestionThinIcon';
import TruckIcon from 'components/icons/TruckIcon';
import UserIcon from 'components/icons/UserIcon';
import WarningIcon from 'components/icons/WarningIcon';

export function getIconElement(value: string): JSX.Element {
  let Element = <></>;

  switch (value) {
    case 'Cart':
      Element = <CartIcon />;
      break;
    case 'User':
      Element = <UserIcon />;
      break;
    case 'Heart':
      Element = <HeartIcon />;
      break;
    case 'Truck':
      Element = <TruckIcon />;
      break;
    case 'Home':
      Element = <HomeIcon />;
      break;
    case 'Money':
      Element = <MoneyIcon />;
      break;
    case 'Warning':
      Element = <WarningIcon />;
      break;
    case 'Calendar':
      Element = <CalendarIcon />;
      break;
    case 'Calendar with date':
      Element = <CalendarWithDateIcon />;
      break;
    case 'Lock':
      Element = <LockIcon />;
      break;
    case 'Money back':
      Element = <ClockwiseIcon />;
      break;
    case 'Info circle':
      Element = <InfoIcon />;
      break;
    case 'Question circle':
      Element = <QuestionThinIcon />;
      break;
    case 'Exclamation circle':
      Element = <ExclamationMarkIcon />;
      break;
    case 'Package':
      Element = <PackageIcon />;
      break;
    case 'Coupon':
      Element = <CouponIcon />;
      break;
    case 'Mobile phone':
      Element = <MobilePhoneIcon />;
  }

  return Element;
}
