import React, { FC, useMemo, useState } from 'react';
import { Variant } from 'shared/types/product/Variant';
import { LineItem } from 'shared/types/wishlist/LineItem';
import { Wishlist } from 'shared/types/wishlist/Wishlist';
import Button from 'components/commercetools-ui/atoms/button';
import Typography from 'components/commercetools-ui/atoms/typography';
import TrashIcon from 'components/icons/TrashIcon';
import { useFormat } from 'helpers/hooks/useFormat';
import useVariantWithDiscount from 'helpers/hooks/useVariantWithDiscount';
import { useCart, useWishlist } from 'frontastic';
import Image from 'frontastic/lib/image';
import { EnergyLabelType, ProductLabels } from '../../product/product-tile';
import EnergyLabel from '../../product/product-tile/components/energy-label';
import ProductCard from '../../product/product-tile/components/product-card';
import ProductTileLabels from '../../product/product-tile/components/product-tile-labels';
import PromotionalInfo from '../../product/product-tile/components/promotional-info';
import Prices from '../../product/product-tile/prices';

export interface Props {
  item: LineItem;
  labels?: ProductLabels;
}

const WishlistItem: FC<Props> = ({ item }) => {
  const variantWithDiscount = useVariantWithDiscount([item?.variant as Variant]);

  const isOnStock = item?.variant?.isOnStock ?? false;

  const labels = useMemo(() => {
    return {
      isDelivery: isOnStock,
      isMadeInPoland: item?.variant?.attributes?.madeInPoland?.value ?? false,
      isOnPromotion: !!(item?.variant?.strikeThroughPrice || item?.variant?.percentOfDiscount),
      energyLabel: {
        energyType: item?.variant?.attributes?.energyClass?.value as EnergyLabelType,
        energyURL: item?.variant?.attributes?.energyLabelUrl?.value,
      },
    } as ProductLabels;
  }, [isOnStock, item]);

  const productCardUrl = item?.variant?.attributes?.productCardUrl;

  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { data: wishlist, removeLineItem } = useWishlist();
  const { addItem } = useCart();
  const [loading, setLoading] = useState(false);

  const moveToCart = async () => {
    setLoading(true);
    if (item.variant) await addItem(item.variant, 1);
    setLoading(false);
  };

  const cartButton = isOnStock ? (
    <Button
      data-test="wishlist-panel-move-to-cart"
      variant="primary"
      onClick={moveToCart}
      disabled={!item.variant?.isOnStock}
      className="px-24 py-12"
      loading={loading}
    >
      <span className="text-16 font-normal leading-5">
        {formatWishlistMessage({ id: 'wishlist.add.to.cart', defaultMessage: 'Add to cart' })}
      </span>
    </Button>
  ) : (
    <Typography className="text-14 leading-[17.5px] text-base-brand-1">
      {formatProductMessage({ id: 'noStock', defaultMessage: 'This Product is not in stock' })}
    </Typography>
  );

  return (
    <div className="flex items-start gap-32 self-stretch">
      {/* Image */}
      <div className="relative flex h-95 w-88 items-center justify-center px-7 py-5">
        {labels?.energyLabel.energyType ? <EnergyLabel energyLabel={labels.energyLabel} /> : null}
        <div className={`relative h-85 w-74 [&>div>span>img]:!max-h-[85px] ${isOnStock ? '' : 'opacity-50'}`}>
          <Image
            placeholder="empty"
            fill={true}
            width={74}
            height={85}
            cloudimage={true}
            src={item.variant?.images?.[0].src}
            alt={item.variant?.images?.[0].alt}
          />
        </div>
      </div>
      <div className="flex grow flex-col gap-16">
        <div className="flex flex-col gap-8">
          {/* Labels and name*/}
          <div className="flex w-full flex-col items-start gap-8">
            {labels ? <ProductTileLabels labels={labels} /> : null}
            <p className="text-16 font-bold leading-[20px]">{item.name}</p>
          </div>
          {/* Price */}
          <Prices
            price={variantWithDiscount?.price ?? item?.variant?.price}
            percentOfDiscount={item?.variant?.percentOfDiscount}
            strikeThroughPrice={item?.variant?.strikeThroughPrice}
            bestPrice30={item.variant?.bestPrice30}
            variant="drawer-wishlist"
            disabled={!isOnStock}
          />
          {/* Historical price */}
          <PromotionalInfo
            strikeThroughPrice={item.variant?.strikeThroughPrice}
            bestPrice30={item.variant?.bestPrice30}
          />

          {/* Product card */}
          {productCardUrl?.value ? <ProductCard productCardUrl={productCardUrl} /> : null}
        </div>

        {/* Button to add to cart and remove button */}
        <div className="flex items-center justify-between self-stretch">
          {cartButton}
          <div className="flex items-center gap-4">
            <i onClick={() => removeLineItem(wishlist as Wishlist, item)} className="block cursor-pointer">
              <TrashIcon />
            </i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishlistItem;
