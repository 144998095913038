import { FC } from 'react';
import { Money } from 'shared/types/product';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';

interface ProductSummaryPromotionalInfo {
  additionalClassName?: string;
  strikeThroughPrice?: Money;
  bestPrice30?: Money;
}

const PromotionalInfo: FC<ProductSummaryPromotionalInfo> = ({
  additionalClassName,
  strikeThroughPrice,
  bestPrice30,
}) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  return strikeThroughPrice || bestPrice30 ? (
    <div className={`text-neutral-3 ${additionalClassName}`}>
      {strikeThroughPrice && (
        <p className="text-14 leading-[21px]">
          <span className="text-14 font-bold leading-[17.5px] line-through">
            {CurrencyHelpers.formatForCurrency(strikeThroughPrice)}
          </span>
          {` ${formatProductMessage({
            id: 'regularPrice',
            defaultMessage: 'Regular price',
          })}`}
        </p>
      )}

      {bestPrice30 && (
        <p className="text-14 leading-[21px]">
          <span className="text-14 font-bold leading-[17.5px] line-through">
            {CurrencyHelpers.formatForCurrency(bestPrice30)}
          </span>
          {` ${formatProductMessage({
            id: 'bestPrice30Text',
            defaultMessage: 'Lowest price since 30 days prior to discount',
          })}`}
        </p>
      )}
    </div>
  ) : null;
};

export default PromotionalInfo;
