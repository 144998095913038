function WarningIcon({ scale = 1 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24 * scale} height={24 * scale} fill="none" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M1.865 20.5L12 3l10.135 17.5H1.865zM4.45 19h15.1L12 6 4.45 19zM12 17.808c.229 0 .42-.078.575-.233a.781.781 0 00.233-.575.781.781 0 00-.232-.576.781.781 0 00-.576-.232.781.781 0 00-.575.232.781.781 0 00-.233.576c0 .229.078.42.233.575a.781.781 0 00.575.233zm-.75-2.616h1.5v-5h-1.5v5z"
      ></path>
    </svg>
  );
}

export default WarningIcon;
